import React, { Component }  from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import style from './style'
import Form, { FormBody, FormSubmit, FormError } from '../../../components/Form'
import { Dropdown, Counter } from '../../../components/Fields'
import Spacing from '../../../components/Spacing'
import Button from '../../../components/Button'
import { required } from '../../../validators'
import { translations, formNames, pdpQtySelectValidationEnabled } from '../../../config'
import P from '../../../components/P'
import { getStockCheckErrors } from '../../../helpers'
import { FormHelperText } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import GroupedProductSwatch from '../../../retailos/ProductCatalogue/GroupedProductSwatch'
const listWidth = 320

export const ProductSingleForm = Form(formNames.productDetails, { destroyOnUnmount: false, enableReinitialize: true, keepDirtyOnReinitialize: true })

class ProductDetailForm extends Component {
  render () {
    const {
      variantsOptions,
      groupedProductOptions,
      groupedProduct,
      onSubmit,
      initialValues,
      classes,
      renderSizeGuide,
      onVariantChange,
      onProductChange,
      isAppointmentActive,
      isSelectedVariantIdInAppointment,
      onVariantAttributeChange,
      availableVariantAttributes = [],
      selectedVariantId,
      selectedVariantStock,
      onMissedSaleClick,
      onOrderProductClick,
      ecommerceStock,
      selectedQuantity,
      enableEndlessAisle,
      enableProductGroupsPDP,
      enableProductGroupSwatchesPDP
    } = this.props
    const inStockStore = selectedVariantStock >= selectedQuantity
    const selectedVariantEcommerceStock = _.get(ecommerceStock ? ecommerceStock[0] : {}, 'stock', 0)
    const inStockEcommerce = selectedVariantEcommerceStock >= selectedQuantity
    const appointmentButtonString = isSelectedVariantIdInAppointment ? translations('Remove from consultation') : translations('Add to consultation')
    const useProductRow = groupedProduct && enableProductGroupsPDP
    const useProductSwatches = useProductRow && enableProductGroupSwatchesPDP

    const renderVariantAttributes = () => {
      if (availableVariantAttributes.length > 0) {
        return availableVariantAttributes.map(attribute => <>
          <P
            value={attribute.name}
            className={classes.rowLabel}
          />
          <div className={classes.dropdownAndSizeGuideContainer}>
            <FormControl className={cx(classes.field, classes.variantDropdownContainer)}>
              <Dropdown
                options={attribute.values.map(attrVal => ({
                  label: attrVal.name,
                  value: attrVal.value
                }))}
                name={attribute.id}
                givenClasses={{ field: classes.dropdown }}
                label={translations('Please select')}
                validate={required}
                noErrorTextLabel
                noFloatingLabel
                onChange={(e) => onVariantAttributeChange({ id: attribute.id, value: e.target.value })}
              />
            </FormControl>
          </div>
        </>)
      } else {
        return null
      }
    }

    const productRow = <>
      <P
        value={translations('title-productOptionTitle') + ':'}
        className={classes.rowLabel}
      />
      <div className={classes.dropdownAndSizeGuideContainer}>
        <FormControl className={cx(classes.field, classes.variantDropdownContainer)}>
          <Dropdown
            options={groupedProductOptions}
            name='productId'
            givenClasses={{ field: classes.dropdown }}
            label={translations('label-pleaseSelect')}
            validate={required}
            noErrorTextLabel
            noFloatingLabel
            onChange={(e) => onProductChange({ productId: e.target.value })}
            listWidth={listWidth}
          />
        </FormControl>
      </div>
    </>

    const productSwatchRow = <>
      <div className={classes.swatchContainer}>
        <GroupedProductSwatch
          selectedOptionId={initialValues.productId}
          options={groupedProductOptions}
          handleOptionSelect={(productId) => onProductChange({ productId })}
        />
      </div>
    </>

    const variantRow = <>
      <P
        value={translations('title-variantTitle') + ':'}
        className={classes.rowLabel}
      />
      <div className={classes.dropdownAndSizeGuideContainer}>
        <FormControl className={cx(classes.field, classes.variantDropdownContainer)}>
          <Dropdown
            options={variantsOptions}
            name='variantId'
            givenClasses={{ field: classes.dropdown }}
            label={translations('label-pleaseSelect')}
            validate={required}
            noErrorTextLabel
            noFloatingLabel
            onChange={(e) => onVariantChange({ variantId: e.target.value })}
            listWidth={listWidth}
          />
        </FormControl>
        <div className={classes.sizeGuideContainer}>
          {renderSizeGuide ? renderSizeGuide() : null}
        </div>
      </div>
    </>

    const quantityRow = <>
      <P
        value={translations('Quantity') + ':'}
        className={classes.rowLabel}
      />
      <div className={cx(classes.field, classes.counterContainer)}>
        <Counter name='quantity' validate={required} noErrorTextLabel />
      </div>
    </>

    const renderProductRow = () => {
      return useProductRow ? (useProductSwatches ? productSwatchRow : productRow) : null
    }

    const renderStockCheckError = () => {
      const errorMessage = getStockCheckErrors(selectedVariantStock, selectedVariantEcommerceStock, selectedQuantity)
      if(errorMessage) {
        return(
          <div className={classes.errorContainer}>
            <ErrorIcon className={classes.errorIcon}/>
            <FormHelperText className={classes.errorStyle}>{errorMessage}</FormHelperText>
          </div>
        )
      }
    }

    return (
      <ProductSingleForm onSubmit={onSubmit} initialValues={initialValues}>
        <FormBody>
          {useProductSwatches ? <Spacing height={10} /> : <Spacing height={20} />}
          <div className={classes.formGrid}>
            {renderVariantAttributes()}
            {renderProductRow()}
            {availableVariantAttributes.length === 0 && variantRow}
            {quantityRow}
            {enableEndlessAisle && pdpQtySelectValidationEnabled && selectedVariantId && renderStockCheckError()}
          </div>
        </FormBody>
        <FormError />
        {enableEndlessAisle ? <>
          <P value={`${translations('Add to basket')}:`} className={classes.rowLabel} />
          <div style={{width:'100%', display:'flex', alignItems:'space-between', marginBottom:'10px'}}>
            <div style={{width:'50%', paddingRight:'5px'}}>
              <FormSubmit
                label={isAppointmentActive ? appointmentButtonString : translations('PDPButton - In-store')}
                buttonType={!inStockStore && inStockEcommerce ? 'white' : 'primary'}
                big
                fullWidth={true}
                disabled={!selectedVariantId}
              />
            </div>
            <div style={{width:'50%', paddingLeft:'5px'}}>
              <Button
                onClick={onOrderProductClick}
                big
                fullWidth={true}
                buttonType={inStockEcommerce && !inStockStore ? 'primary' : 'white'}
                disabled={!selectedVariantId || !inStockEcommerce}
              >
                {translations('PDPButton - Order')}
              </Button>
            </div>
          </div>
        </>
          : <><Spacing height={20} />
        <FormSubmit
          label={isAppointmentActive ? appointmentButtonString : translations('Add to basket')}
          containerClass={classes.submitButtonContainer}
          buttonClass={classes.submitButton}
          big
          disabled={!selectedVariantId}
        /></>
        }

        <div>
          {(selectedVariantId && !selectedVariantStock) && (
            <Button
              hyperlink
              onClick={onMissedSaleClick}
              fullWidth={true}
              disabled={selectedVariantStock}
            >
              {translations('Record missed sale - button')}
            </Button>
          )}
        </div>
      </ProductSingleForm>
    )
  }
}

ProductDetailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  preSelectedVariant: PropTypes.string
}

export default withStyles(style)(ProductDetailForm)
