import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import { compose, withHandlers, withPropsOnChange, lifecycle } from 'recompose'
import _, { isObject } from 'lodash'
import ProductDetailForm from './ProductDetailForm'
import { formNames, translations } from '../../../config'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../../store/modules/currentOrder'
import { actions as currentAppointmentActions, selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'
import { selectors as productDetailsSelectors } from '../../../store/modules/productDetails'
import { actions as categoriesActions, selectors as categoriesSelectors } from '../../../store/modules/categories'
import * as variantsCombinedSelectors from '../../../store/modules/combinedSelectors/variantsCombinedSelectors'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { replace } from 'connected-react-router'
import { selectors as appSelectors } from '../../../store/modules/app'
import modalService from '../../../services/modalService'
import MissedSaleSelectCustomerScreen from '../MissedSaleSelectCustomer'
import CustomerRegistrationScreen from '../../../containers/Customer/CustomerRegistrationScreen'
import { goBack } from 'connected-react-router'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { getProductCatalogueConfigForBrand, getCheckoutConfigForBrand } from '../../../store/modules/combinedSelectors/regionsCombinedSelectors'

const pdpProductKeys = ['id', 'name', 'variants', 'brand', 'link', 'price', 'discount', 'images', 'categoryId', 'externalProductId', 'preview', 'service', 'clientId', 'vatPercent']

const getCombinedVariantName = (variantAttributes, selectedAttributes) => {
  const names = []
  for (var attr in selectedAttributes) {
    const matchedAttr = _.find(variantAttributes, varAttr => varAttr.id === attr)
    if (matchedAttr) {
      const selected = _.find(matchedAttr.values, values => values.value === selectedAttributes[attr])
      if (selected) {
        names.push(selected.name)
      }
    }
  }
  return names.join(' - ')
}

const mapStateToProps = (state, { overrideProductDetailForm }) => {
  if (overrideProductDetailForm) {
    return overrideProductDetailForm
  }
  const selectedProducts = currentAppointmentSelectors.getAppointmentProducts(state)
  const formValues = getFormValues(formNames.productDetails)(state)
  const selectedVariantId = _.get(formValues, 'variantId')
  const isSelectedVariantIdInAppointment = !!_.find(selectedProducts, selected => selected.variant.id === selectedVariantId)
  const store = authSelectors.getSelectedStore(state)
  const product = productDetailsSelectors.getProductForSelectedCurrency(state)
  const parentCategory = categoriesSelectors.getParentCategoryById(state, product.categoryId)
  const ecommerceStock = variantsCombinedSelectors.getEcommerceStock(state)
  const selectedQuantity = _.get(formValues, 'quantity')
  const productCatalogueConfig = getProductCatalogueConfigForBrand(state)
  const enableProductGroupsPDP = _.get(productCatalogueConfig, 'groupedProductsEnabledPDP', false)
  const enableProductGroupSwatchesPDP = _.get(productCatalogueConfig, 'groupedProductsEnableSwatchesPDP', false)
  const checkoutConfig = getCheckoutConfigForBrand(state)
  const selectedRegion = authSelectors.getUserSelectedRegion(state)
  const enableEndlessAisle = isObject(_.get(checkoutConfig, 'enableEndlessAisle')) ? _.get(checkoutConfig, `enableEndlessAisle.${_.get(selectedRegion, 'externalRegionId')}`, false) : _.get(checkoutConfig, 'enableEndlessAisle', false)
  const groupedProduct = productDetailsSelectors.getGroupedProducts(state).length > 0
  const productGroupOptions = productDetailsSelectors.getProductGroupOptions(state)

  return {
    formValues,
    currencyCode: appSelectors.getAppCurrency(state),
    isSelectedVariantIdInAppointment,
    selectedProducts: selectedProducts,
    selectedVariantId: selectedVariantId,
    variants: productDetailsSelectors.getVariants(state),
    variantsOptions: productDetailsSelectors.getVariantsOptions(state, enableProductGroupsPDP),
    groupedProductOptions: productGroupOptions,
    product,
    groupedProduct,
    enableProductGroupsPDP,
    enableProductGroupSwatchesPDP,
    isAppointmentActive: currentAppointmentSelectors.getIsAppointmentActive(state),
    variantAttributes: productDetailsSelectors.getVariationAttributes(state),
    variantAttributeNames: productDetailsSelectors.getVariationAttributeNames(state),
    storeId: _.get(store, 'id'),
    parentCategoryName: _.get(parentCategory, 'name', ''),
    ecommerceStock,
    selectedQuantity,
    enableEndlessAisle
  }
}

const getAvailableVariantAttributes = ({
  variantAttributes,
  variantAttributeNames,
  formValues,
  variants
}) => {
  const selectedAttributes = _.chain(formValues)
    .pick(variantAttributeNames)
    .pickBy(_.identity)
    .value()

  const isCleanForm = Object.keys(selectedAttributes).length === 0

  if (isCleanForm) return variantAttributes

  const availableVariantAttributes = []

  const availableVariantsForKey = (keyName) => variants.filter(variant => {
    const variationValues = variant.details.variation_values
    const otherSelectedAttributes = _.omit(selectedAttributes, [keyName])
    let allPositive = true
    _.toPairs(otherSelectedAttributes).forEach(values => {
      const keyName = values[0]
      const keyValue = values[1]
      allPositive = allPositive && keyValue === variationValues[keyName]
    })

    return allPositive
  })

  variantAttributes.forEach(attribute => {
    const attributeId = attribute.id
    const isSelected = !!selectedAttributes[attributeId]
    const isOnlySelected = isSelected && Object.keys(selectedAttributes).length === 1

    if (isOnlySelected) {
      availableVariantAttributes.push(attribute)
    } else {
      const availableVariants = availableVariantsForKey(attributeId)
      availableVariantAttributes.push({
        ...attribute,
        values: attribute.values.filter(attrVals => {
          return !!_.find(availableVariants, available => {
            return available.details.variation_values[attributeId] === attrVals.value
          })
        })
      })
    }
  })

  return availableVariantAttributes
}

const recordMissedSale = ({ dispatch, customerId, variantId, productId, storeId, details }) => {
  digitalStoreSdk.missedSale.createMissedSale({ customerId, variantId, productId, storeId, details })
    .then((res) => {
      modalService.continue({
        title: translations('Missed sale saved'),
        confirmButtonText: translations('Ok')
      })
    })
    .catch((error) => console.log('missed sale error', error))
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(
    [
      'variantsOptions',
      'variantAttributes',
      'variantAttributeNames',
      'formValues',
      'variants',
      'selectedVariantId',
      'product'
    ],
    ({
      variantsOptions,
      variantAttributes,
      formValues,
      variantAttributeNames,
      variants,
      selectedVariantId,
      product
    }) => ({
      initialValues: {
        quantity: 1,
        productId: product.id,
        variantId: (
          variantsOptions.length === 1
            ? _.get(variantsOptions, '0.value')
            : null
        )
      },
      selectedVariantStock: productDetailsSelectors.getSelectedVariantStock({selectedVariantId, variants}),
      availableVariantAttributes: getAvailableVariantAttributes({
        variantAttributes,
        variantAttributeNames,
        formValues,
        variants
      })
    })
  ),
  withHandlers({
    onSubmit: ({
      dispatch,
      product,
      isAppointmentActive,
      isSelectedVariantIdInAppointment,
      variantAttributes,
      variantAttributeNames
    }) => (formValues) => {
      const {
        quantity,
        variantId
      } = formValues

      const selectedAttributes = _.pick(formValues, variantAttributeNames)
      const combinedVariantName = getCombinedVariantName(variantAttributes, selectedAttributes)

      const productObj = { ...(_.pick(product, pdpProductKeys)) }
      const productWithVariant = currentOrderSelectors.attachVariantToProduct({ product: productObj, variantId, variantName: combinedVariantName })

      if (isAppointmentActive) {
        if (isSelectedVariantIdInAppointment) {
          dispatch(currentAppointmentActions.removeContent({ type: 'product', details: productWithVariant, removeVariant: true }))
        } else {
          dispatch(currentAppointmentActions.addContent({ type: 'product', details: productWithVariant }))
        }
      } else {
        dispatch(currentOrderActions.addProduct({ product: { ...productWithVariant, stock: 'Y' }, quantity }))
      }
    },
    onOrderProductClick: ({ dispatch, isAppointmentActive, product, currencyCode, formValues, variantAttributes, variantAttributeNames }) => () => {
      const {
        quantity,
        variantId
      } = formValues
      const productObj = { ...(_.pick(product, pdpProductKeys)) }
      const selectedAttributes = _.pick(formValues, variantAttributeNames)
      const combinedVariantName = getCombinedVariantName(variantAttributes, selectedAttributes)
      const productWithVariant = currentOrderSelectors.attachVariantToProduct({ product: productObj, variantId, variantName: combinedVariantName })
      if (isAppointmentActive) {
        if (isSelectedVariantIdInAppointment) { // TODO: Variable isn't defined - possible bug
          dispatch(currentAppointmentActions.removeContent({ type: 'product', details: productWithVariant, removeVariant: true }))
        } else {
          dispatch(currentAppointmentActions.addContent({ type: 'product', details: { ...productWithVariant, stock: 'N' } }))
        }
      } else {
        dispatch(currentOrderActions.addProduct({ product: { ...productWithVariant, stock: 'N' }, quantity }))
      }
    },
    onVariantChange: ({ dispatch, variants, product }) => ({ variantId }) => {
      if (variantId) {
        const currentProductId = product.id
        const nextProductId = _.chain(variants)
          .find(v => v.id === variantId)
          .get('productId')
          .value()

        // reload the page if the product id is different
        if (nextProductId && nextProductId !== currentProductId) {
          dispatch(replace(`/product/${nextProductId}`))
        } else {
          dispatch(change(formNames.productDetails, 'variantId', variantId))
        }
      }
    },
    onProductChange: ({ dispatch, product }) => ({ productId }) => {
      if (productId) {
        const currentProductId = product.id
        const nextProductId = productId

        if (nextProductId && nextProductId !== currentProductId) {
          dispatch(replace(`/product/${nextProductId}`))
        }
      }
    },
    onVariantAttributeChange: ({ dispatch, formValues, variantAttributeNames, variants, product }) => ({ id, value }) => {
      const newValues = {
        ...formValues,
        [id]: value
      }
      const currentProductId = product.id

      dispatch(change(formNames.productDetails, id, value))
      const allAttributesSelected = variantAttributeNames.every(item => {
        return Object.prototype.hasOwnProperty.call(newValues, item) && newValues[item] !== null
      })
      if (allAttributesSelected) {
        const variantValues = _.toPairs(_.pick(newValues, variantAttributeNames))
        const match = _.find(variants, variant => {
          let allPositive = true
          const variationValues = variant.details.variation_values
          variantValues.forEach(values => {
            const keyName = values[0]
            const keyValue = values[1]
            allPositive = allPositive && keyValue === variationValues[keyName]
          })

          return allPositive
        })
        const variantId = match.id
        if (currentProductId !== variantId) {
          dispatch(change(formNames.productDetails, 'variantId', variantId))
          dispatch(replace(`/product/${variantId}`))
        } else {
          dispatch(change(formNames.productDetails, 'variantId', variantId))
        }
      }
    },
    onMissedSaleClick: ({ dispatch, selectedVariantId, product, storeId, parentCategoryName, currencyCode }) => () => {
      const originalPrice = _.get(product, ['details', 'prices', currencyCode], '')
      const discount = _.get(product, ['details', 'discounts', currencyCode], 0)
      const priceAfterDiscount = originalPrice - discount
      modalService.open({
        modalIndex: 1,
        component: MissedSaleSelectCustomerScreen,
        hideBottomBar: true,
        fullScreen: true,
        inModal: true,
        registerCustomer: () => {
          modalService.open({
            modalIndex: 2,
            component: CustomerRegistrationScreen,
            hideBottomBar: true,
            fullScreen: true,
            inModal: true,
            onBackClick: () => {
              modalService.close({ modalIndex: 2 })
            },
            afterSubmit: customer => {
              recordMissedSale({
                dispatch,
                customerId: _.get(customer, 'id'),
                variantId: selectedVariantId,
                productId: _.get(product, 'id'),
                storeId,
                details: {
                  price: {
                    code: currencyCode,
                    value: priceAfterDiscount
                  },
                  brand: _.get(product, 'brand', ''),
                  category: parentCategoryName
                }
              })
              dispatch(goBack())
              modalService.close({ modalIndex: 1 })
              modalService.close({ modalIndex: 2 })
            }
          })
        },
        onCustomerClick: customer => {
          recordMissedSale({
            dispatch,
            customerId: _.get(customer, 'id'),
            variantId: selectedVariantId,
            productId: _.get(product, 'id'),
            storeId,
            details: {
              price: {
                code: currencyCode,
                value: priceAfterDiscount
              },
              brand: _.get(product, 'brand', ''),
              category: parentCategoryName
            }
          })
          modalService.close({ modalIndex: 1 })
        },
        onBackClick: () => modalService.close({ modalIndex: 1 })
      })
    }
  })
)(ProductDetailForm)
