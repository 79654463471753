import { connect } from 'react-redux'
import _, { isObject } from 'lodash'
import { compose, withHandlers, withPropsOnChange, withProps } from 'recompose'
import { push } from 'connected-react-router'
import { destroy as destroyForm, getFormValues } from 'redux-form'
//import copy from 'copy-to-clipboard'

import { selectors as productDetailSelectors, actions as productDetailsActions, selectors as productDetailsSelectors } from '../../../store/modules/productDetails'
import ProductDetailScreen from './ProductDetailScreen'
import modalService from '../../../services/modalService'
import analyticsService from '../../../services/analyticsService'
import toastService from '../../../services/toastService'

import store from '../../../store'
import { actions as customerDetailsActions } from '../../../store/modules/customerDetails'
import { actions as createMessageActions } from '../../../store/modules/createMessage'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { translations, productDetails as productDetailsConfig, apps, formNames, getAppConfig } from '../../../config'
import { selectors as currentOrderSelectors, actions as currentOrderActions } from '../../../store/modules/currentOrder'
import * as currentOrderCombinedSelectors from '../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import * as productDetailsCombinedSelectors from '../../../store/modules/combinedSelectors/productDetailsCombinedSelectors'
import { actions as productWaitlistActions } from '../../../store/modules/productWaitlist'
import { actions as categoriesActions } from '../../../store/modules/categories'
import { selectors as networkConnectors } from '../../../store/modules/network'
import { selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'
import { selectors as appSelectors } from '../../../store/modules/app'
import { selectors as storesSelectors } from '../../../store/modules/stores'

import MarkdownScreen from '../../../components/MarkdownScreen'
import HorizontalGallery from './../../../components/HorizontalGallery'
import ProductDetailCustomerSearch from './ProductDetailCustomerSearch'

import { convertUrlWithUTMParams } from '../../../helpers/convertUrlWithUTMParams'
import { copyToClipboard as copy } from '../../../helpers/clipboard'

import CustomerSearchScreen from '../../../containers/Customer/CustomerSearch'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { getCheckoutConfigForBrand, getProductCatalogueConfigForBrand } from '../../../store/modules/combinedSelectors/regionsCombinedSelectors'

const mapStateToProps = state => {
  const userId = authSelectors.getActiveUserId(state);
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)
  const selectedProducts = currentAppointmentSelectors.getAppointmentProducts(state)
  const isMessagingEnabled = isMessagingAllowedForRole && productDetailsConfig.messaging && apps.MESSAGING
  const productDetails = productDetailsCombinedSelectors.getCurrentProductDetails(state)
  const storeId = authSelectors.getUserSelectedStoreId(state)
  const formValues = getFormValues(formNames.productDetails)(state)
  const selectedVariantId = _.get(formValues, 'variantId')
  const productDetailsVariants = productDetailsSelectors.getVariants(state)
  const currentOrderCustomer = currentOrderSelectors.getCurrentOrderCustomer(state)
  const currentOrderCustomerId = _.get(currentOrderCustomer, 'id', '')
  const selectedCurrency = appSelectors.getAppCurrency(state)
  const isStoresLoading = storesSelectors.getStoresIsLoading(state)
  const isProductDetailLoading = productDetailSelectors.getIsLoading(state)
  const checkoutConfig = getCheckoutConfigForBrand(state)
  const selectedRegion = authSelectors.getUserSelectedRegion(state)
  const enableEndlessAisle = isObject(_.get(checkoutConfig, 'enableEndlessAisle')) ? _.get(checkoutConfig, `enableEndlessAisle.${_.get(selectedRegion, 'externalRegionId')}`, false) : _.get(checkoutConfig, 'enableEndlessAisle', false)
  const productCatalogueConfig = getProductCatalogueConfigForBrand(state)
  const enableProductGroupsPDP = _.get(productCatalogueConfig, 'groupedProductsEnabledPDP', false)

  return {
    ...productDetails,
    selectedCurrency,
    selectedProducts,
    selectedVariantId,
    currentOrderCustomerId,
    productDetailsVariants,
    isMessagingEnabled,
    isLoading: isProductDetailLoading || isStoresLoading,
    productError: productDetailSelectors.getProductError(state),
    tabValue: productDetailSelectors.getBottomTab(state),
    isConnected: networkConnectors.isConnected(state),
    storeId,
    enableEndlessAisle,
    enableProductGroupsPDP,
    userId,
    ..._.pick(currentOrderCombinedSelectors.currentOrderSelector(state), [
      'editMode',
      'orderNumber'
    ])
  }
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(['selectedProducts'], ({ selectedProducts, id }) => {
    return {
      isSelected: !!_.find(selectedProducts, (selected) => selected.id === id)
    }
  }),
  withProps({
    hideOnlineStock: !productDetailsConfig.onlineStock,
    hideInStoreStock: !productDetailsConfig.inStoreStock,
    hideInStoreOverallStock: !productDetailsConfig.InStoreOverallStock
  }),
  withHandlers({
    fetchProductDetails: (props) => () => {
      const { dispatch, storeId, match, id } = props
      const productId = id || match.params.id
      dispatch(productDetailsActions.fetchProduct({ id: productId, storeId }))
    },
    fetchAllCategories: (props) =>  () => {
      const { dispatch } = props
      dispatch(categoriesActions.fetchAllCategories())
    },
    onSearchClick: ({ dispatch, product, selectedVariantId, productDetailsVariants, currentOrderCustomerId }) => () => {
      const updateCustomersWishlist = ({ id, details }) => {
        const updatedData = {
          ...details,
          wishlist: details && details.wishlist ? details.wishlist.map((item) => {
            return {
              ...item,
              price: {
                ...item.price,
                value: String(parseFloat(_.get(item, 'price.value')) - (parseFloat(_.get(item, 'details.discounts[item.price.code]', 0)) || 0))
              }
            }
          }) : []
        }

        dispatch(customerDetailsActions.updateCustomerWishlist({ id, details: updatedData }))
        if (currentOrderCustomerId === id) {
          dispatch(currentOrderActions.updateOrderWishlist({ id, details: updatedData }))
        }
        modalService.close()
      }
      modalService.open({
        component: ProductDetailCustomerSearch,
        hideBottomBar: true,
        fullScreen: true,
        onCustomerClick: customer => {
          const currentWishlist = _.get(customer, 'details.wishlist', [])
          const customerFullName = `${customer.firstName} ${customer.lastName}`
          const productExists = currentWishlist.find(obj => obj.id === product.id)
          if (!productExists) {
            const wishlistObject = {
              id: product.id,
              brand: product.brand,
              images: product.images,
              link: product.link || '',
              name: product.name,
              price: product.price,
              type: 'product',
              details: product.details,
              externalProductId: product.externalProductId,
              selectedVariantId: selectedVariantId || null,
              productVariants: productDetailsVariants,
              categoryId: product.categoryId
            }
            const wishlistUpdated = [wishlistObject].concat(currentWishlist)
            return updateCustomersWishlist({
              id: customer.id,
              details: { wishlist: wishlistUpdated }
            })
          } else {
            modalService.continue({
              title: translations('Wishlist item already exists title'),
              text: translations('this item already exists text', { customer: customerFullName })
            })
          }
        },
        onBackClick: () => modalService.close()
      })
    },
    onClickCopyProductLink: ({storeId, userId}) => (url) => {
      if (url) {
        modalService.open({
          component: CustomerSearchScreen,
          fullScreen: true,
          onCustomerClick: async (customer) => {
            const state = store.getState()
            const urlWithUTMParams = convertUrlWithUTMParams(state, url)
           

            try {
              const message = {
                communicationType: "other",
                customerId: customer.id,
                details: {
                  content: [],
                  other: "whatsapp",
                  templateId: "",
                  text: urlWithUTMParams,
                },
                storeId: storeId,
                updateSource: "RetailOS",
                userId: userId
              }
              const result = await digitalStoreSdk.messages.sendMessage({...message});
              if (_.get(result, 'id')){
                copy(urlWithUTMParams)
                modalService.close()
              }
              else {
                toastService.action({
                  type: 'error',
                  message: translations("Sorry, an error has occurred. Please try again."),
                  verticalPosition: 'top',
                  horizontalPosition: 'right'
                })
              }
            }
            catch (e) {
              toastService.action({
                type: 'error',
                message: translations("Sorry, an error has occurred. Please try again."),
                verticalPosition: 'top',
                horizontalPosition: 'right'
              })
            }

          },
          onBackClick: () => modalService.close()
        })
      } else {
        toastService.action({
          type: 'warning',
          message: translations('Product URL does not exist'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      }
    },
    onClickAddMessage: ({ dispatch, product }) => () => {
      const state = store.getState();
      product.variants && product.variants.map((variant) => {
        variant.link = convertUrlWithUTMParams(state,variant.link);
      })
      const productLink = _.get(product, 'link')
      const productContent = {
        ...product,
        link: convertUrlWithUTMParams(state, productLink),
      }
      
      dispatch(createMessageActions.addContentToMessage({ content: [{
        type: 'product',
        ...productContent,
        displayPrice: productDetailsSelectors.getPriceDisplayForProduct(product)
      }] }))
    },
    handleTabChange: ({ dispatch }) => (event, value) => {
      dispatch(productDetailsActions.changeProductBottomTab({ productTab: value }))
    },
    openHTMLModal: ({ dispatch, product, careInstructions }) => () => {
      analyticsService.sendCustomPDPEvent({ name: 'careInstructionsClick' })
      modalService.open({
        component: MarkdownScreen,
        markdownData: careInstructions,
        inModal: true,
        success: () => modalService.close()
      })
    },
    openSizeGuideModal: ({ dispatch, product, sizeGuide }) => () => {
      analyticsService.sendCustomPDPEvent({ name: 'sizeGuideClick' })
      modalService.open({
        component: MarkdownScreen,
        markdownData: sizeGuide,
        inModal: true,
        success: () => modalService.close()
      })
    },
    openGalleryModal: ({ product }) => () => {
      return modalService.open({
        component: HorizontalGallery,
        zoomModal: true,
        fullScreen: true,
        images: product.images || [],
        embeddedVideos: product.embeddedVideos || [],
        videoThumbnails: product.videoThumbnails || [],
        inModal: true
      })
    },
    stopEditingOrder: ({ dispatch }) => () => {
      dispatch(currentOrderActions.stopEditingOrder())
    },
    onClickShowWaitlist: ({ dispatch, product }) => () => {
      dispatch(push(`/product/${product.id}/waitlist`))
    },
    onClickAddToWaitlist: ({ dispatch, product, selectedVariantId }) => () => {
      modalService.open({
        component: ProductDetailCustomerSearch,
        hideBottomBar: true,
        fullScreen: true,
        onCustomerClick: customer => {
          dispatch(productWaitlistActions.addToProductWaitlist({
            productId: product.id,
            customerId: customer.id,
            variantId: selectedVariantId
          }))
            .then(() => {
              modalService.close()
              modalService.continue({
                title: translations('Added to waitlist title'),
                text: translations('Added to waitlist text', {
                  customer: `${customer.firstName} ${customer.lastName}`
                })
              })
            })
            .catch(err => {
              modalService.close()
              modalService.continue({
                title: translations('Adding to waitlist failed title'),
                text: translations(err.code)
              })
            })
        },
        onBackClick: () => modalService.close()
      })
    },
    cleanUpForm: ({ dispatch }) => () => {
      dispatch(destroyForm(formNames.productDetails))
    },
    onVariantChange: ({ dispatch, variants, product }) => ({ variantId }) => {
      if (variantId) {
        const currentProductId = product.id
        const nextProductId = _.chain(variants)
          .find(v => v.id === variantId)
          .get('productId')
          .value()

        // reload the page if the product id is different
        if (nextProductId && nextProductId !== currentProductId) {
          dispatch(replace(`/product/${nextProductId}`))
        } else {
          dispatch(change(formNames.productDetails, 'variantId', variantId))
        }
      }
    },
  })
)(ProductDetailScreen)
