import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../config'
import SearchInput from '../../../../components/SearchInput'
import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import style from './style'
import ScanButton from '../ScanButton'

class ProductsSearchBar extends Component {
  state = {
    query: this.props.query
  }

  componentDidUpdate (prevProps) {
    if (prevProps.query !== this.props.query) {
      this.setState({ query: this.props.query })
    }
  }

  searchProducts = _.debounce(({ query }) => {
    const { searchProducts, category, push, globalSearchEnabled } = this.props
    if (globalSearchEnabled && category) {
      // If global search enabled, then search all products, don't filter by category
      searchProducts({ query, category: null })
      push('/products')
    } else {
      searchProducts({ query })
    }
  }, 300)

  updateQuery = query => {
    this.setState({ query })
    this.searchProducts({ query })
  }

  // doesn't use this.searchProducts so debounce is avoided
  // makes it snappier
  clearQuery = () => {
    const { searchProducts } = this.props
    this.setState({ query: '' })
    searchProducts({ query: null })
  }

  onChange = e => {
    this.updateQuery(e.target.value)
  }

  render () {
    const { category, goBack, isLoading, classes, searchBarPosition, globalSearchEnabled } = this.props
    const placeholder = category && !globalSearchEnabled
      ? _.template(translations('Search category'))({ category: _.toLower(category.name) })
      : translations('Search products')
    return (
      <SubHeader
        absolute={searchBarPosition === 'absolute'}
        fixed={searchBarPosition === 'fixed'}
        isLoading={isLoading}
        leftContent={(
          <BackBar onClick={this.state.query ? this.clearQuery : goBack} />
        )}
        centerContent={(
          <div className={classes.mainContainer}>
            <SearchInput
              value={this.state.query || ''}
              placeholder={placeholder}
              onChange={this.onChange}
              autoFocus={false}
            />
            <div className={classes.rightButtonContainer}>
              <ScanButton />
            </div>
          </div>
        )}
      />
    )
  }
}

export default withStyles(style)(ProductsSearchBar)
