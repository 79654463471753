import { connect } from 'react-redux'
import { compose } from 'recompose'
import { get } from 'lodash'
import SearchBar from './SearchBar'
import { actions as productsActions, selectors as productsSelectors } from '../../../../store/modules/products'
import { getProductCatalogueConfigForBrand } from '../../../../store/modules/combinedSelectors/regionsCombinedSelectors'

const mapStateToProps = state => {
  const checkoutConfig = getProductCatalogueConfigForBrand(state)
  const query = productsSelectors.getQuery(state)
  const category = productsSelectors.getCategory(state)
  const isLoading = productsSelectors.getIsLoading(state)
  const globalSearchEnabled = get(checkoutConfig, 'globalSearchEnabled', false)
  return {
    query,
    category,
    isLoading,
    globalSearchEnabled
  }
}

const mapDispatchToProps = dispatch => ({
  searchProducts: ({ query, category }) => {
    dispatch(productsActions.searchProductsFresh({ query, category }))
  }
})

// this prioritises ownProps.query over stateProps.query allowing query to be set
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...dispatchProps,
  ...stateProps,
  query: ownProps.query || stateProps.query
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(SearchBar)
