import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetFormValues } from '../../../../hooks/formHooks'
import { useIsLoading } from '../../../../hooks/productsHooks'
import { submit, change } from 'redux-form'
import CircularProgress from '@material-ui/core/CircularProgress'
import  { getAppConfig, } from '../../../../config' 

import _ from 'lodash'
import styled from 'styled-components'

import { selectors as productsSelectors, actions as productsActions } from '../../../../store/modules/products'
import { selectors as appSelectors } from '../../../../store/modules/app'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as categoriesSelectors, actions as categoriesActions } from '../../../../store/modules/categories'
import { selectors as configSelectors } from '../../../../store/modules/config'
import { selectors as storeSelectors } from '../../../../store/modules/stores'
import { getCurrencyConfigForSelectedRegion } from '../../../../store/modules/combinedSelectors/regionsCombinedSelectors'
import Button from '../../../../components/Button'
import { translations } from '../../../../config'
import Form, { FormBody } from '../../../../components/Form'

export const formId = 'consultationCustomerDetailsForm'

const SearchFilterForm = Form(formId)

const ButtonContainer = styled.div`
  margin-top: 10px;
`

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.8);
  z-index: 999;
`

const SearchFilters = ({
  onClose,
  regionName
}) => {
  const dispatch = useDispatch()
  const selectedCurrency = useSelector(appSelectors.getAppCurrency)
  const availableFilters = useSelector(productsSelectors.getAvailableFilters)
  const currencyConfig = useSelector(getCurrencyConfigForSelectedRegion)
  const storeId = useSelector(authSelectors.getUserSelectedStoreId)
  const ecommStoreId = useSelector(authSelectors.getUserSelectedEcommStoreId)
  const getCategory = useSelector(productsSelectors.getResultsCategory)
  const isInCategory = !!getCategory
  const categoryFilter = useSelector(categoriesSelectors.getRootCategories).map(cat => ({
    'value': cat.id,
    'label': cat.name
  }))
  
  const activeFilters = useSelector(productsSelectors.getActiveFilters)
  const updatedFilters = useMemo(() => {
    const availableStoresArray = _.get(activeFilters, 'available_stores', [])
    return {
      ...activeFilters, 
      available_store: _.includes(availableStoresArray, storeId) || false,
      available_ecomm: _.includes(availableStoresArray, ecommStoreId) || false
    }
  }, [activeFilters, storeId, ecommStoreId])
  
  const numberOfActiveFilters = useSelector(productsSelectors.getNumberOfActiveFilters)
  const remoteConfig = useSelector(configSelectors.getConfig)
  const formValues = useGetFormValues(formId)
  const isLoading = useIsLoading()
  const getNewFilters = (filters) => _
    .chain(filters)
    .mapValues((filterValue) => _.isArray(filterValue) ? filterValue.filter(_.identity) : filterValue)
    .pickBy(filterValue => {
      return _.isArray(filterValue) ? !!filterValue.length : _.identity
    })
    .value()

  const onSubmit = useCallback(() => {
    let prevFilters = getNewFilters(formValues)
    let newFilters = _.omit(prevFilters, ['available_stores', 'available_store', 'available_ecomm'])
    const availableStore = _.get(prevFilters, 'available_store')
    const availableEcomm = _.get(prevFilters, 'available_ecomm')
    if (availableStore || availableEcomm) {
      newFilters.available_stores = [availableStore ? storeId : null, availableEcomm ? ecommStoreId : null].filter(Boolean)
    } 
    dispatch(productsActions.searchProductsFresh({ filters: newFilters }))
  })

  const handleInputChange = useCallback(async (e) => {
    const name = _.get(e, 'target.name')
    const value = _.get(e, 'target.value')
    await dispatch(change(formId, name, value))
    await dispatch(submit(formId))
  }, [])

  const handleCheckboxChange = useCallback(async (e) => {
    const name = _.get(e, 'target.name')
    const checked = _.get(e, 'target.checked')
    await dispatch(change(formId, name, checked))
    await dispatch(submit(formId))
  }, [])

  const handlePriceChange = useCallback((field, value) => {
    dispatch(change(formId, field, value))
    dispatch(submit(formId))
  }, [])

  const clearFilters = useCallback(() => {
    dispatch(productsActions.searchProductsFresh({ filters: {} }))
    onClose && onClose()
  })

  const filtersSchema = useMemo(() => {
    const excludes = _.get(remoteConfig, 'filterExcludes', [])

    const allAvailableFilters = Object.assign(availableFilters, {
      category: categoryFilter,
      available_store: storeId,
      available_ecomm: ecommStoreId,
    })
    let formattedFilters = []
    for (const [key, value] of Object.entries(allAvailableFilters)) {
      if (key.startsWith('price')) {
        const filter = {
          id: key,
          label: 'Price',
          values: value
        }

        if (key === `price${selectedCurrency}`) {
          formattedFilters.push(filter)
        }
      } else {
        formattedFilters.push({
          id: key,
          label: _.startCase(key.replace('variant', '')),
          values: value
        })
      }
    }
    const toBeMapped = formattedFilters.filter(filter => !excludes.find(exclude => exclude === filter.id))
    const disabledFilters = getAppConfig('CATALOG', 'filters', [], regionName)['disabledFilters'] || []
    return _.compact(toBeMapped.map(filter => {
      if (_.size(filter.values) === 0 || disabledFilters.find(id => id === filter.id) ) return // hide empty filters
      if (filter.id === 'price' || filter.id === `price${selectedCurrency}`) {
        const range = _.isNumber(_.get(filter, 'values.min')) && _.isNumber(_.get(filter, 'values.max'))
        if (range) {
          return {
            id: filter.id,
            field: 'Slider',
            props: {
              name: filter.id,
              label: filter.label,
              min: 0,
              max: parseInt(_.get(filter, 'values.max')),
              onChange: _.debounce((value) => handlePriceChange(filter.id, value), 500)
            }
          }
        }
      } else if (filter.id === 'available_store') {
        return {
          id: filter.id,
          field: 'Checkbox',
          props: {
            label: 'label-hideUnavailableStores',
            name: filter.id,
            onChange: handleCheckboxChange
          }
        }
      } else if (filter.id === 'available_ecomm') {
        return {
          id: filter.id,
          field: 'Checkbox',
          props: {
            label: 'label-hideUnavailableEcomm',
            name: filter.id,
            onChange: handleCheckboxChange
          }
        }
      } else if (filter.id === 'category') {
        if (!isInCategory) {
          return {
            id: filter.id,
            field: 'Dropdown',
            props: {
              name: filter.id,
              label: filter.label,
              value: filter.value || [],
              options: filter.values.map(option => ({
                label: option.label,
                value: option.value
              })),
              multiple: true,
              onChange: handleInputChange
            }
          }
        }
      } else {
        return {
          id: filter.id,
          field: 'Dropdown',
          props: {
            name: filter.id,
            label: filter.label,
            options: _.orderBy(filter.values, ['value'], ['asc']).map(option => ({
              label: option.value.charAt(0).toUpperCase() + option.value.slice(1),
              value: option.value
            })),
            multiple: true,
            onChange: handleInputChange
          }
        }
      }
    }))
  }, [availableFilters, selectedCurrency, currencyConfig])

  return <>
    <SearchFilterForm
      editing={!isLoading}
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={updatedFilters}
    >
      <FormBody
        schema={filtersSchema}
        fullWidthFields
      />
    </SearchFilterForm>
    {numberOfActiveFilters > 0 && <ButtonContainer>
      <Button fullWidth onClick={clearFilters}>
        {translations('Clear Filters')}
      </Button>
    </ButtonContainer>}
    <ButtonContainer>
      <Button fullWidth onClick={onClose}>
        {translations('Close')}
      </Button>
    </ButtonContainer>
    {isLoading && (<LoadingOverlay>
      <CircularProgress size={50} />
    </LoadingOverlay>)}
  </>
}

export default SearchFilters
