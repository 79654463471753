import React, { Fragment, Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { translations, productDetails as productDetailsConfig } from '../../../config'
import ProductDetailForm from './../ProductDetailForm'
import ProductDiscount from './../../../components/ProductDiscount'
import HorizontalGallery from './../../../components/HorizontalGallery'
import ProductDetailBottomTabs from '../../ProductDetail/ProductDetailScreen/ProductDetailBottomTabs'
import MarkdownScreen from '../../../components/MarkdownScreen'
import BackBar from '../../../components/BackBar'
import Spacing from '../../../components/Spacing'
import H1 from '../../../components/H1'
import H2 from '../../../components/H2'
import P from '../../../components/P'
import Button from '../../../components/Button'
import LoaderContainer from '../../../components/LoaderContainer'
import MultiSelectBar from '../../Products/common/MultiSelectBar'
import FullScreenProgress from '../../../components/FullScreenLoader'
import FloatingBadge from '../../../components/FloatingBadge'
import StockIndicator from './StockIndicator'
import { getImage } from '../../../components/Images'
import EditMode from '../../../components/EditMode'
import style from './style'
import ContentCopyIcon from '@material-ui/icons/FileCopy'
import { Avatar } from '@material-ui/core'

const wishlistIcon = getImage('wishlistIcon')
const messageIcon = getImage('messageIcon')
const addToWaitlistIcon = getImage('addToWaitlistIcon')
const viewWaitlistIcon = getImage('viewWaitlistIcon')

class ProductDetailScreen extends Component {
  componentDidMount() {
    const preSelectedVariant = _.get(this.props.match, 'params.variantId', '')
    this.props.fetchProductDetails()
    this.props.fetchAllCategories()
    if(preSelectedVariant){
      this.props.onVariantChange({ variantId: preSelectedVariant })
    }
  }

  componentWillUnmount() {
    this.props.cleanUpForm()
  }

  _renderSizeGuide = () => {
    const { sizeGuide, openSizeGuideModal, classes } = this.props
    return productDetailsConfig.sizeGuide && sizeGuide &&
      <Button
        onClick={openSizeGuideModal}
        className={classes.sizeGuideButton}
        hyperlink
        iconImage={'shirtSizeIcon'}
      >
        {translations('View size guide')}
      </Button>
  }

  _renderProductName = () => {
    const { classes, product, enableProductGroupsPDP } = this.props
    // Legacy productGroup functionality
    if (!enableProductGroupsPDP && product.productGroup) {
      return (
        <H1 value={`${product.productGroup} - ${product.name}`} className={classes.title} />
      )
    } else {
      return (
        <H1 value={`${product.name}`} className={classes.title} />
      )
    }
  }

  render () {
    const {
      classes,
      displayPrice,
      product,
      productError,
      onSearchClick,
      onClickAddMessage,
      handleTabChange,
      tabValue,
      openHTMLModal,
      productSummary,
      displayDiscount,
      originalPrice,
      discountValue,
      openGalleryModal,
      editMode,
      orderNumber,
      stopEditingOrder,
      isMessagingEnabled,
      careInstructions,
      onClickShowWaitlist,
      onClickCopyProductLink,
      onClickAddToWaitlist,
      isVirtualConsultationCustomerScreen = false,
      overriddenOnlineAvailability,
      hideOnlineStock,
      hideInStoreStock,
      hideInStoreOverallStock,
      enableEndlessAisle,
      overrideProductDetailForm,
      goBack,
      multiSelect,
      isLoading,
      match,
      selectedVariantId
    } = this.props

    const discountProductValue = _.get(product, 'discount.value')
    const isPurchasable = ((product && product.price && product.price.value) || (product && displayPrice))
    const selectedVariant = product && product.variants ? product.variants.filter(item => selectedVariantId === item.id)[0] : null

    if (productError) {
      return (
        <Fragment>
          <BackBar onClick={goBack || undefined} />
          <div className={classes.errorContainer}>
            <P className={classes.errorText} type='textButton' value={translations('Load Product Error')} />
          </div>
        </Fragment>
      )
    }
    
    const producturl = (selectedVariant && selectedVariant.link) || product.link

    return (
      <div className={classes.root}>
        {!isVirtualConsultationCustomerScreen && <>
          <BackBar onClick={goBack || undefined} />
          <div className={classes.iconsContainer}>
            {producturl &&
              <div onClick={() => onClickCopyProductLink(producturl)} className={classes.iconImage}>
                <Avatar style={{ backgroundColor: 'black', width: 46, height: 46 }}>
                  <ContentCopyIcon style={{ color: 'white', fontSize: 'x-large' }} />
                </Avatar>
              </div>
            }
            {productDetailsConfig.waitlist && <>
              <img src={viewWaitlistIcon} className={classes.iconImage} onClick={onClickShowWaitlist} />
              <img src={addToWaitlistIcon} className={classes.iconImage} onClick={onClickAddToWaitlist} />
            </>}
            {productDetailsConfig.wishlist &&
              <img src={wishlistIcon} className={classes.iconImage} onClick={onSearchClick} />}
            {isMessagingEnabled &&
              <img src={messageIcon} className={classes.iconImage} onClick={onClickAddMessage} />}
          </div>
        </>}
        {editMode && <EditMode orderNumber={orderNumber} stopEditingOrder={stopEditingOrder} />}
        <LoaderContainer isLoading={isLoading}>
          <Grid container spacing={0} className={classes.contentContainer}>
            <Grid item xs={12} sm={6} className={classes.galleryContainer}>
              <HorizontalGallery
                images={product.images || []}
                embeddedVideos={product.embeddedVideos || []}
                videoThumbnails={product.videoThumbnails || []}
                openExpandedModal={openGalleryModal}
              />
              {product.preview && <FloatingBadge label={translations('Preview')} position={'topRight'} />}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.productContent}>
              <div>
                <div className={classes.titleGroupContainer}>
                  <div className={classes.titleContainer}>
                    {this._renderProductName()}
                    <H2 value={product.brand} className={classes.subTitle} />
                    {productDetailsConfig.sku && <P value={`${translations('SKU')}: ${product.externalProductId}`} className={classes.sku} />}
                    {(selectedVariant && selectedVariant.details && selectedVariant.details.binNumber) && <P value={`${translations('PDP Bin Number')}: ${selectedVariant.details.binNumber}`} className={classes.sku} />}
                  </div>
                </div>
                {product.promotionalText && productDetailsConfig.promoText && (
                  <div className={classes.promotionalBadgeContainer} >
                    <div className={classes.promotionalBadge} ><P value={product.promotionalText} type='promoText' /></div>
                  </div>
                )}
                {(!product._isProductGroupContainer && isPurchasable) ?
                  <ProductDiscount
                    isPreviewProduct={product.preview}
                    originalPrice={originalPrice}
                    displayDiscount={displayDiscount}
                    displayPrice={displayPrice}
                    discountValue={discountValue}
                    left
                    classes={{ price: classes.price }}
                  /> : <h2>{translations('Unavailable')}</h2>
                }
                <Spacing height={14} />
                {productDetailsConfig.stock && (
                  <StockIndicator
                    overriddenOnlineAvailability={overriddenOnlineAvailability}
                    hideOnlineStock={hideOnlineStock}
                    hideInStoreStock={hideInStoreStock}
                    hideInStoreOverallStock={hideInStoreOverallStock}
                    hideEcommStock={!enableEndlessAisle}
                  />
                )}
              </div>
              {!isPurchasable && <Spacing height={20} /> }
              { isPurchasable && <ProductDetailForm
                renderSizeGuide={this._renderSizeGuide}
                overrideProductDetailForm={overrideProductDetailForm}
                preSelectedVariant={_.get(match, 'params.variantId', '')}
              /> }
              {productDetailsConfig.shortProductDescription && productSummary && <MarkdownScreen markdownData={productSummary} />}
              {
                productDetailsConfig.careInstructions && careInstructions &&
                  <Button onClick={openHTMLModal} hyperlink className={classes.careButton}>{translations('Care Instructions')}</Button>
              }
            </Grid>
          </Grid>
          <ProductDetailBottomTabs handleTabChange={handleTabChange} tabValue={tabValue} product={product} />
          {multiSelect && <MultiSelectBar {...this.props} />}
        </LoaderContainer>
      </div>
    )
  }
}

ProductDetailScreen.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string
  }),
  classes: PropTypes.shape({}).isRequired,
  displayPrice: PropTypes.string.isRequired,
  productError: PropTypes.string,
  onClickShowWaitlist: PropTypes.func.isRequired
}

export default withStyles(style)(ProductDetailScreen)
