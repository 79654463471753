import _ from 'lodash'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { compose } from 'recompose'
import StockIndicator from './StockIndicator'

import { formNames } from '../../../../config'

const mapStateToProps = (state) => {
  const formValues = getFormValues(formNames.productDetails)(state)
  const selectedVariantId = _.get(formValues, 'variantId', null)
  return {
    selectedVariantId
  }
}

export default compose(connect(mapStateToProps))(StockIndicator)