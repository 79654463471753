import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'
import { apiUpdateSource } from '../../../config'

class ProductWaitlistActions {
  fetchProductWaitlist = (params) => ({
    type: constants.FETCH_WAITLIST,
    promise: () => {
      const { productId, storeId, customerId, expand, page = 1, size = 10 } = params
      return digitalStoreSdk.productWaitlist.fetchProductWaitlist(params)
      .then(result => ({
        results: result.items,
        productId,
        storeId,
        customerId,
        expand,
        page,
        size
      }))
    }
  })

  fetchProductWaitlistNext = () => ({
    type: constants.FETCH_WAITLIST_NEXT,
    promise: (dispatch, getState) => {
      const { productWaitlist: { page, productId, storeId, expand, size } } = getState()
      const nextPage = page + 1
      digitalStoreSdk.productWaitlist.fetchProductWaitlist({
        productId,
        storeId,
        expand,
        page: nextPage,
        size
      })
      .then(result => ({
        results: result.items,
        page: nextPage
      }))
    }
  })

  addToProductWaitlist = params => ({
    type: constants.ADD_TO_WAITLIST,
    promise: (dispatch, getState) => {
      const currentStore = authSelectors.getSelectedStore(getState())

      return digitalStoreSdk.productWaitlist.addToProductWaitlist({
        ...params,
        storeId: currentStore.id,
        updateSource: apiUpdateSource,
      })
    }
  })

  deleteEntryFromWaitlist = productId => ({
    type: constants.DELETE_FROM_WAITLIST,
    promise: () => {
      return digitalStoreSdk.productWaitlist.deleteFromProductWaitlist(productId)
    }
  })

  deleteEntryFromWaitlistLocal = entryId => ({
    type: constants.DELETE_FROM_WAITLIST_LOCAL,
    entryId
  })

  clearWaitlist = () => ({
    type: constants.CLEAR_WAITLIST_RESULTS
  })

  addToCustomerProductWaitlist = ({ productIds, storeId, customerId, replace }) => ({
    type: constants.ADD_TO_CUSTOMER_WAITLIST,
    payload: { customerId },
    promise: (dispatch, getState) => {
      return digitalStoreSdk.productWaitlist.addToCustomerProductWaitlist({
        replace,
        storeId,
        customerId,
        productIds,
        updateSource: apiUpdateSource,
      })
    }
  })
}

export default new ProductWaitlistActions()
