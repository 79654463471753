import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import SearchBar from '../common/SearchBar'
import Results from '../common/Results'
import CategoriesGrid from './CategoriesGrid'
import MultiSelectBar from '../common/MultiSelectBar'
import LoaderContainer from '../../../components/LoaderContainer'
class ProductsRootScreen extends Component {
  render () {
    const { query, resultsQuery, multiSelect, isLoading } = this.props
    return (
      <Fragment>
        <SearchBar {...this.props} query={query} />
        <LoaderContainer isLoading={isLoading}>
          {query && resultsQuery ? (
            <Results {...this.props} />
          ) : (
            <CategoriesGrid {...this.props} />
          )}
        </LoaderContainer>
        {multiSelect && <MultiSelectBar {...this.props} />}
      </Fragment>
    )
  }
}

ProductsRootScreen.propTypes = {
  query: PropTypes.string,
  resultsQuery: PropTypes.array,
  multiSelect: PropTypes.bool
}

export default ProductsRootScreen
