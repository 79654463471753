import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import _, { get } from 'lodash'

import { selectors as authSelectors, constants as authConstants } from '../auth'
import productsActions from './actions'
import * as productsSelectors from './selectors'
import * as productsConstants from './constants'
import { SUCCESS } from '../../middleware/redux-promise'
import analyticsService from '../../../services/analyticsService'
import { getProductCatalogueConfigForBrand } from '../combinedSelectors/regionsCombinedSelectors'

class ProductsMiddleware {
  loadInitialProductsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const isRoutingToProducts = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/products'
    const productsIsInitial = productsSelectors.getIsInitial(getState())
    const isLoggedIn = authSelectors.getIsLoggedIn(getState())
    if (isRoutingToProducts && productsIsInitial && isLoggedIn) {
      dispatch(productsActions.searchProductsFresh({}))
    }
  }
  resetStoreOnUserLogout = ({ dispatch, getState }) => next => (action) => {
    if (action.type === authConstants.LOGOUT && action.status === SUCCESS) {
      dispatch(productsActions.resetStore())
    }
    next(action)
  }

  clearProductSearch = ({ dispatch, getState }) => next => (action) => {
    if (action.type === LOCATION_CHANGE) {
      const pathBefore = _.get(getState(), 'router.location.pathname')
      const isRoutingFromPlp = !!matchPath(pathBefore, { path: '/products', exact: true })
      const isRoutingFromPdp = !!matchPath(pathBefore, { path: '/product', exact: false })
      const isRoutingFromProductSubCategories = !!matchPath(pathBefore, { path: '/products/cat', exact: false })
      next(action)
      const pathAfter = _.get(getState(), 'router.location.pathname')
      const isRoutingToPlp = !!matchPath(pathAfter, { path: '/products', exact: true })
      const isRoutingToPdp = !!matchPath(pathAfter, { path: '/product', exact: false })
      const isRoutingToProductSubCategories = !!matchPath(pathAfter, { path: '/products/cat', exact: false })
      const fromPLPToPDP = isRoutingFromPlp && isRoutingToPdp && !isRoutingToPlp
      const fromPDPToPLP = isRoutingFromPdp && !isRoutingToPdp && isRoutingToPlp
      const fromPDPToCategory = isRoutingFromPdp && isRoutingToProductSubCategories
      const fromPLPToCategory = isRoutingFromPlp && isRoutingToProductSubCategories
      const fromCategoryToPLP = isRoutingFromProductSubCategories && isRoutingToPlp
      
      const checkoutConfig = getProductCatalogueConfigForBrand(getState())
      const globalSearchEnabled = get(checkoutConfig, 'globalSearchEnabled', false)

      const shouldClearSearchFilters = () => {
        // clear search filters only when in root products page.
        // keep search filters when navigating to PDP
        // Keep search filters when global search is being used so routing between PLP and /cat/id route
        if (globalSearchEnabled) {
          return ((isRoutingToPlp && !fromPDPToPLP && !fromPDPToCategory && !fromCategoryToPLP) || (isRoutingFromPlp && !fromPLPToPDP && !fromPLPToCategory))
        } else {
          return ((isRoutingToPlp && !fromPDPToPLP && !fromPDPToCategory) || (isRoutingFromPlp && !fromPLPToPDP))
        }
      }

      if(shouldClearSearchFilters()) {
        dispatch(productsActions.searchProductsFresh({ category: null, query: null, sort: null }))
      }
    }
    next(action)
  }

  resetProductsOnProductPageLoad = ({ dispatch, getState }) => next => action => {
    // const isRoutingToProducts = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/products'
    // const isLoggedIn = authSelectors.getIsLoggedIn(getState())
    // if (isRoutingToProducts && isLoggedIn) {
    //   dispatch(productsActions.resetStore())
    // }
    next(action)
  }

  resetFiltersOnCategoryChangeMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === productsConstants.SEARCH_PRODUCTS_FRESH) {
      const currentCategory = productsSelectors.getCategory(getState())
      const newCategory = action.category
      if (newCategory && newCategory.id !== _.get(currentCategory, 'id')) {
        dispatch(productsActions.resetStore())
      }
    }
    next(action)
  }
}

export default new ProductsMiddleware()
