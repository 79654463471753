import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import InStoreStock from './InStoreStock'
import InStoreOverallStock from './InStoreOverallStock'
import OnlineStock from './OnlineStock'
import StockInOtherStores from './StockInOtherStores'
import EcommerceStock from './EcommerceStock'
import { actions as variantsStockActions } from '../../../../store/modules/variantsStock'
import style from './style'

const StockIndicator = ({ classes, overriddenOnlineAvailability, hideOnlineStock, hideInStoreStock, hideInStoreOverallStock, hideEcommStock, selectedVariantId}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!selectedVariantId) return
    dispatch(variantsStockActions.fetchVariantStoreStocks({ variantId: selectedVariantId }))
  }, [selectedVariantId])

  return (
    <div className={classes.stockOutter}>
      <div className={classes.stockInner}>
        <div className={classes.indicators}>
          {!hideOnlineStock && <OnlineStock overriddenOnlineAvailability={overriddenOnlineAvailability} />}
          {!hideInStoreStock && <InStoreStock />}
          {!hideInStoreOverallStock && <InStoreOverallStock />}
          {!hideEcommStock && <EcommerceStock/>}
        </div>
        <div className={classes.indicators}>
          {<StockInOtherStores />}
        </div>
      </div>
    </div>
  )
}

export default withStyles(style)(StockIndicator)
