import { LOGIN, LOGOUT, SELECT_STORE, UPDATE_AUTH_USER, SELECT_DEPARTMENT, REMOVE_RECENT_USER, SET_AUTH_CONTEXT, GET_SSO_AUTH_CONTEXT, SET_IS_USING_RECENT_USER, GET_SELECTED_STORE_DETAILS, GET_SELECTED_REGION_ECOMMERCE_STORE_ID } from './constants'
import reducers from './reducers'

const defaultState = {
  user: undefined,
  status: undefined,
  error: undefined,
  action: undefined,
  recentUsers: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN:
      return reducers.login(state, action)
    case LOGOUT:
      return reducers.logout(state, action)
    case SELECT_STORE:
      return reducers.selectStore(state, action)
    case UPDATE_AUTH_USER:
      return reducers.updateUser(state, action)
    case SELECT_DEPARTMENT:
      return reducers.selectDepartment(state, action)
    case REMOVE_RECENT_USER:
      return reducers.removeRecentUser(state, action)
    case SET_AUTH_CONTEXT:
      return reducers.getAuthContext(state, action)
    case GET_SSO_AUTH_CONTEXT:
      return reducers.getSSOAuthContext(state, action)
    case SET_IS_USING_RECENT_USER:
      return reducers.setIsUsingRecentUser(state, action)
    case GET_SELECTED_STORE_DETAILS:
      return reducers.getSelectedStoreDetails(state, action)
    case GET_SELECTED_REGION_ECOMMERCE_STORE_ID:
      return reducers.getSelectedRegionEcommerceStoreId(state, action)
    default:
      return state
  }
}
