import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modules, i18n } from '@redant/retailos-ui'
import {
  retailAnalyticsFunctions
} from '../dependencies'
import { environment } from '../../config'
import { selectors as appSelectors } from '../../store/modules/app'
import enRetailAnalytics from '../i18n/en/RetailAnalytics.json'
import arRetailAnalytics from '../i18n/ar/RetailAnalytics.json'
import _ from 'lodash'
import {
  selectors as authSelectors
} from '../../store/modules/auth'

const languageSelect = (language) => {
  switch (language) {
    case 'en':
      i18n.addResourceBundle('chalhoub:en', 'RetailAnalytics', enRetailAnalytics, true, true)
      i18n.changeLanguage('chalhoub:en')
      break
      case 'ar':
      i18n.addResourceBundle('chalhoub:ar', 'RetailAnalytics', arRetailAnalytics, true, true)
      i18n.changeLanguage('chalhoub:ar')
      break
    default:
      i18n.changeLanguage('chalhoub:en')
      break
  }
}

export const RetailAnalyticsScreen = () => {
  const selectedLanguage = useSelector(appSelectors.getAppLanguage)
  const region = useSelector(authSelectors.getUserSelectedRegion)
  const sisenseWat = _.get(region, 'details.sisenseWat')

  useEffect(() => {
    languageSelect(selectedLanguage)
  }, [selectedLanguage])

  const implementation = Modules.RetailAnalytics.Implementation.provideUseRetailAnalyticsScreen({
    retailAnalyticsFunctions: retailAnalyticsFunctions,
    sisenseConfig: {
      url: environment.SISENSE_URL,
      watToken: sisenseWat,
      dashboardId: environment.SISENSE_DASHBOARD_ID
    }
  })

  return (
    <Modules.RetailAnalytics.Screens.RetailAnalyticsScreen
      implementation={implementation}
    />
  )
}
