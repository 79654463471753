import _ from 'lodash'

import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import { INVALID_PASSWORD_ERROR, MAX_LOGIN_ATTEMPTS } from './constants'

const updateRecentUsers = (state, action) => {
  const { id, username, firstName, lastName } = action.result
  const { offlinePasswordHash } = action
  if (!username) return state.recentUsers
  // Get recent users capped to 15 without current user
  const recentUsers = (state.recentUsers || [])
    .filter(user => user.id !== id) // Filter out user if already added
    .filter((_, i) => i < 14)

  // Prepend current user and return
  return [{
    ...action.result,
    token: null,
    refreshToken: null,
    offlinePasswordHash,
    loginAttempts: 0
  }].concat(recentUsers)
}

const updateRecentUsersWithFailedLoginAttempt = (state, action) => {
  const user = _.get(action.error, 'user' || {})
  const { id, username, loginAttempts } = user
  if (!username) return state.recentUsers
  // Get recent users capped to 15 without current user
  const recentUsers = (state.recentUsers || [])
    .filter(user => user.id !== id) // Filter out user if already added
    .filter((_, i) => i < 14)

  if (loginAttempts === MAX_LOGIN_ATTEMPTS - 1) {
    return recentUsers
  }

  // Prepend current user and return
  return [{
    ...user,
    loginAttempts: loginAttempts + 1
  }].concat(recentUsers)
}

class AuthReducers {
  login = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: { ...state.user, ...action.result },
          recentUsers: updateRecentUsers(state, action),
          loginMethod: 'DIGITAL_STORE',
          error: {}
        }
      case FAIL:
        if (_.get(action.error, 'code') === INVALID_PASSWORD_ERROR) {
          return {
            ...state,
            status: FAIL,
            recentUsers: updateRecentUsersWithFailedLoginAttempt(state, action),
            error: action.error
          }
        }
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  logout = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: undefined
        }
      case FAIL:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: undefined
        }
      default:
        return state
    }
  }

  selectStore = (state, action) => ({
    ...state,
    user: {
      ...state.user,
      selectedStoreId: action.storeId,
      selectedRegionId: action.regionId
    }
  })

  getSelectedStoreDetails = (state, action) => {
    const giftNoteEnabled = _.get(action, 'result.region.details.giftNote', false)
    const trackingParameters = _.get(action, 'result.region.details.trackingParameters')
    return {
      ...state,
      selectedStore: {
        ..._.omit(action.result, 'region'),
        giftNoteEnabled,
        ...trackingParameters && { trackingParameters }
      }
    }
  }
  getSelectedRegionEcommerceStoreId = (state, action) => {
    const regionStores = _.get(action, 'result.items', [])
    const selectedEcommerceStoreId = _.find(regionStores, store => _.get(store, 'details.locationType') === 'ecommerceStore')
    return {
      ...state,
      selectedRegionEcommerceStore: _.get(selectedEcommerceStoreId, 'id')
    }
  }

  selectDepartment = (state, action) => ({
    ...state,
    user: {
      ...state.user,
      selectedDepartmentId: action.departmentId
    }
  })

  updateUser = (state, action) => {
    const { user } = action
    return {
      ...state,
      user: {
        ...state.user,
        ...user
      }
    }
  }

  removeRecentUser = (state, action) => ({
    ...state,
    recentUsers: state.recentUsers.filter(user => user.id !== action.id)
  })

  getSSOAuthContext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: { ...state.user, ...action.result },
          loginMethod: 'SSO',
          error: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  getAuthContext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: { ...state.user, ...action.result },
          error: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  setIsUsingRecentUser = (state, action) => {
    return {
      ...state,
      isUsingRecentUser: action.isUsingRecentUser
    }
  }
}
export default new AuthReducers()
