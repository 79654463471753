import _ from 'lodash'
import { change } from 'redux-form'

import {
  MAX_LOGIN_ATTEMPTS,
  INVALID_PASSWORD_ERROR,
  SET_IS_USING_RECENT_USER,
  LOGIN,
  RESET_PASSWORD,
  LOGOUT,
  SELECT_STORE,
  UPDATE_AUTH_USER,
  SELECT_DEPARTMENT,
  REMOVE_RECENT_USER,
  SET_AUTH_CONTEXT,
  GET_SSO_AUTH_CONTEXT,
  REFRESH_AUTH_CONTEXT,
  GET_SELECTED_STORE_DETAILS,
  GET_SELECTED_REGION_ECOMMERCE_STORE_ID
} from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import * as offlinePasswordHandler from '../../../helpers/offlinePasswordHandler'
import { selectors as networkSelectors } from '../network'
import * as selectors from './selectors'

class AuthActions {
  login = ({ email, username, password, isCustomerMode, isUsingRecentUser }) => ({
    type: LOGIN,
    isCustomerMode,
    isUsingRecentUser,
    offlinePasswordHash: offlinePasswordHandler.hash({ password }),
    promise: async (dispatch, getState) => {
      const state = getState()
      const isConnected = networkSelectors.isConnected(state)
      if (isConnected) {
        return digitalStoreSdk.auth.login({ email, username, password })
      }
      // offline auth flow
      const recentUsers = selectors.getRecentUsers(state)
      const recentUser = _.find(recentUsers, (ruser) => (ruser.username === username || ruser.email === email))
      if (!isUsingRecentUser || !recentUser) {
        dispatch(change('login', 'username', ''))
        throw new Error('can only login with recent users when in offline mode')
      }
      const passwordValid = offlinePasswordHandler.compare({
        password,
        offlinePasswordHash: recentUser.offlinePasswordHash
      })
      if (!passwordValid) {
        let error
        if (_.get(recentUser, 'loginAttempts') === MAX_LOGIN_ATTEMPTS - 1) {
          dispatch(change('login', 'username', ''))
          error = new Error('maximum login attempts used. please go online to login with this user')
        } else {
          error = new Error('invalid password please try again')
        }
        error.code = INVALID_PASSWORD_ERROR
        error.user = recentUser
        throw error
      }
      return { ...recentUser }
    }
  })

  resetPassword = ({ email, username }) => ({
    type: RESET_PASSWORD,
    promise: () => digitalStoreSdk.auth
      .resetPassword({ email, username })
  })

  logout = ({ skipApi } = {}) => ({
    type: LOGOUT,
    promise: () => digitalStoreSdk.auth.logout({ skipApi })
  })

  selectStore = ({ storeId, regionId, silent = false }) => ({
    type: SELECT_STORE,
    storeId,
    regionId,
    silent
  })

  selectDepartment = ({ departmentId, silent = false }) => ({
    type: SELECT_DEPARTMENT,
    departmentId,
    silent
  })

  updateUser = ({ user }) => ({
    type: UPDATE_AUTH_USER,
    user
  })

  removeRecentUser = ({ id }) => ({
    type: REMOVE_RECENT_USER,
    id
  })

  setAuthContext = ({ currentStoreId, currentDepartmentId }) => ({
    type: SET_AUTH_CONTEXT,
    currentStoreId,
    currentDepartmentId,
    promise: () => digitalStoreSdk.auth
      .setAuthContext({ currentStoreId, currentDepartmentId })
  })

  getSSOAuthContext = () => ({
    type: GET_SSO_AUTH_CONTEXT,
    promise: () => digitalStoreSdk.auth
      .getAuthContext()
  })

  refreshAuthContext = () => ({
    type: REFRESH_AUTH_CONTEXT,
    promise: (dispatch) => digitalStoreSdk.auth
      .getAuthContext()
      .then(user => dispatch(this.updateUser(user)))
  })

  setIsUsingRecentUser = (isUsingRecentUser) => ({
    type: SET_IS_USING_RECENT_USER,
    isUsingRecentUser
  })

  getSelectedStoreDetails = (id) => ({
    type: GET_SELECTED_STORE_DETAILS,
    promise: () => digitalStoreSdk.stores
      .fetchStore({ id, fields: 'id,externalStoreId,name,details', includes: ['region'] })
  })

  getSelectedRegionEcommerceStoreId = (regionId) => ({
    type: GET_SELECTED_REGION_ECOMMERCE_STORE_ID,
    promise: () => digitalStoreSdk.stores
      .fetchStores({
        regionId: `${regionId}`, 
        fields: 'id,details'
      })
  })
}

export default new AuthActions()
